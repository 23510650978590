import {createBrowserRouter} from "react-router-dom";
import {createPrivateRoute} from "../providers/PrivateRoute";
import {Portfolio} from "../pages/Portfolio";
import {Account, accountLoader} from "../pages/Account";
import {Login} from "../pages/Login";
import {QueryClient} from "@tanstack/react-query";
import {LayoutPage, portfolioLoader} from "../pages/LayoutPage";

export const queryClient = new QueryClient()

export const router = createBrowserRouter([
    createPrivateRoute([
        {
            loader: portfolioLoader(queryClient),
            element: <LayoutPage/>,
            children: [
                {
                    path: "/",
                    element: <Portfolio/>,
                    //errorElement: <ErrorBoundary />
                },
                {
                    path: "accounts/:accountId",
                    loader: accountLoader,
                    element: <Account/>
                }
            ]
        },
    ]),
    {
        path: "/login",
        element: <Login/>
    }
])
//TODO Сделать красивую страницу загрузки