import {ColoredValuePercents} from "./ColoredValuePercents";
import React from "react";

export interface IValue {
    percents: number,
    money: number,
    currency: string,
    type: string,
    time: string,
}

export const DrawdownView = ({percents, money, currency, type, time}: IValue) => {
    return (
        <div>
            <div style={{fontSize: "10px"}}>в {type}</div>
            <ColoredValuePercents value={percents} style={{fontSize: "20px"}}/>
            <div style={{fontSize: "11px", fontWeight: "700"}}>{money}{currency}</div>
            <div style={{fontSize: "11px", fontWeight: "700"}}>{time}</div>
        </div>
    )
}