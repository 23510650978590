import {Tag, theme} from "antd";
import {Link} from "react-router-dom";

const {useToken} = theme;

export interface IAccountInfo {
    id: string,
    name: string,
    days: number,
    type: string,
    sellOrdersCount: number,
    buyOrdersCount: number
}

export const AccountInfoCell = (props: IAccountInfo) => {
    const {token} = useToken();
    const {name, id, days, type, sellOrdersCount, buyOrdersCount} = props
    return (
        <>
            <div style={{fontSize: "18px"}}>
                <Link to={`/accounts/${id}`}>{name}</Link>
            </div>
            <div style={{fontSize: "12px"}}>
                <span style={{color: token.cyan7, marginRight: 4}}>{days} {type}</span>
                {buyOrdersCount !== 0 && <Tag style={{padding: "0 4px", marginRight: 4, fontWeight: 700}} color={token.green4}>{buyOrdersCount}</Tag>}
                {sellOrdersCount !== 0 && <Tag style={{padding: "0 4px", marginRight: 0, fontWeight: 700}} color={token.red5}>{sellOrdersCount}</Tag>}
            </div>
        </>
    )
}