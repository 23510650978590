import {LoaderFunction, Navigate, Outlet, redirect, RouteObject} from "react-router-dom";
import {LoaderFunctionArgs} from "@remix-run/router/utils";
import {getAuth} from "../helpers/Auth";

export const PrivateRoute = () => {

    // Получаем значение isAuthenticated из пользовательского хука useAuth
    //const { isAuthenticated } = useAuth()
    // const isAuthenticated: boolean = false;

    // Получаем текущий маршрут из хука useLocation
    // const location = useLocation()
    // let auth = {token: false}
    // let { userInfo } = useAuth()
    // let auth = getAuth()

    // Возвращаем условный оператор для рендеринга компонентов на основе состояния isAuthenticated
   // redirect("/login");
    return (
        // Если пользователь авторизован, то рендерим дочерние элементы текущего маршрута, используя компонент Outlet
        getAuth() ?
        // auth.token ?
           <Outlet />
            // Если пользователь не авторизован, то перенаправляем его на маршрут /login с помощью компонента Navigate.
            // Свойство replace указывает, что текущий маршрут будет заменен на новый, чтобы пользователь не мог вернуться обратно, используя кнопку "назад" в браузере.
           :
            <Navigate to="/login" replace />
         //    <Navigate to="/login" state={{ from: location }} replace />
    )
};

const createPrivateLoader = (loader: LoaderFunction) => (args: LoaderFunctionArgs) =>
    getAuth() ? loader(args) : redirect("/login")

export const createPrivateRoute = (routes: RouteObject[]) => {
    return {
        element: <PrivateRoute/>,
        children: routes.map(route => {
            if(route.loader !== undefined) {
                route.loader = createPrivateLoader(route.loader)
            }
            return route
        })
    }
}