import {Table} from "antd";
import {MoneyInfoCell} from "./MoneyInfoCell";
import {theme} from "antd";

const {useToken} = theme;

export interface SummaryType {
    totalBalance: number,
    currency: string,
    today: number,
    todayPercent: number,
    drawdown: number,
    drawdownPercent: number,
    total: number,
    totalPercent: number
}

export const PortfolioSummary = (summary: SummaryType) => {
    const {token} = useToken();

    return (
        <>
            <Table.Summary.Row style={{fontSize: "12px"}}>
                <Table.Summary.Cell index={0} colSpan={4} >Капитализация</Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                    <span style={{color: token.cyan7, fontSize: "20px"}}>${summary.totalBalance}</span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                    <MoneyInfoCell
                        percent={summary.todayPercent}
                        real={summary.today}
                        currency={summary.currency}
                    />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                    <MoneyInfoCell
                        percent={summary.drawdownPercent}
                        real={summary.drawdown}
                        currency={summary.currency}
                    />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                    <MoneyInfoCell
                        percent={summary.totalPercent}
                        real={summary.total}
                        currency={summary.currency}
                    />
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </>
    );
}