import {theme} from "antd";
import {CSSProperties} from "react";

const {useToken} = theme;

export interface IValue {
    value: number,
    style?: CSSProperties | undefined;
}

export const ColoredValuePercents = ({value, style}: IValue) => {
    const {token} = useToken();
    const color = value > 0 ? token.colorSuccess : value < 0 ? token.colorError : token.colorTextBase
    // const style = style != null ? style.
    return (
        <>
            <div style={{...style, color: color}}>{value > 0 ? "+" : ""}{value}%</div>
        </>
    )
}