import React from 'react';
import './App.css';
import {ConfigProvider, theme} from "antd";
import {RouterProvider} from "react-router-dom";
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient, router} from "./config/RouterConfig";

function App() {
    return (
        <ConfigProvider theme={{
            algorithm: theme.darkAlgorithm,
            token: {
                colorBgBase: "#101113",
                colorPrimary: "#f4801a",
                colorTextBase: "#c0c0c6",
                colorInfo: "#f4801a",
            },
            components: {
                Layout: {
                    headerBg: "#232529",
                    headerHeight: 46,
                    headerPadding: "0 5px"
                },
                Table: {
                    headerBorderRadius: 0
                }
            },
            cssVar: true
        }}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}/>
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
