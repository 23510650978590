import axios from "axios";
import {router} from "./RouterConfig";
import {getAuth} from "../helpers/Auth";
import Cookies from "js-cookie";

export const publicApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const privateApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

privateApi.interceptors.request.use(config => {
    const controller = new AbortController();
    if (!getAuth()) {
        controller.abort("Authorization required")
        return router.navigate("/login").then(_ => config);
    }
    return config
});

privateApi.interceptors.response.use(response => {
    if (!getAuth()) {
        return router.navigate("/login").then(_ => response);
    }
    return response
}, error => {
    if (error.response.status === 401 || !getAuth()) { //TODO error.response.status === 403 если ресурс запрещен возможно обрабатывать его как не найденный
        Cookies.remove("auth")
        return router.navigate("/login");
    }
    return error;
});