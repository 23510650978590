import {Button, Card, Checkbox, Form, FormProps, Input} from "antd";
import {publicApi} from "../config/AxiosConfig";
import {Navigate, useNavigate} from "react-router-dom";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {getAuth} from "../helpers/Auth";

interface LoginType {
    login: string;
    password: string;
    remember: boolean;
}

// const onFinish: FormProps<LoginType>['onFinish'] = (values) => {
//     console.log('Success:', values);
// };

// const onFinishFailed: FormProps<LoginType>['onFinishFailed'] = (errorInfo) => {
//     console.log('Failed:', errorInfo);
// };

export const Login = () => {
    const navigate = useNavigate();
    const onSubmit: FormProps<LoginType>['onFinish'] = (loginData) => {
        publicApi.post('/auth/login', loginData, {withCredentials: true})
            .then(() => navigate('/'))
            .catch((err) => console.log(err));
    }
    return (
        getAuth() ? <Navigate to="/" replace/> :
            <>
                <Card title="Sign in" style={{marginTop: 50, maxWidth: 400, margin: "0 auto"}}>
                    <Form
                        name="Sign in"
                        initialValues={{remember: true}}
                        onFinish={onSubmit}
                        // onFinishFailed={onFinishFailed}
                    >
                        <Form.Item<LoginType>
                            name="login"
                            rules={[{required: true, message: 'Please input your login!'}]}
                        >
                            <Input
                                placeholder="Username"
                                prefix={<UserOutlined style={{color: 'rgba(255,255,255,.25)'}}/>}
                            />
                        </Form.Item>

                        <Form.Item<LoginType>
                            name="password"
                            rules={[{required: true, message: 'Please input your password!'}]}
                        >
                            <Input.Password
                                placeholder="Password"
                                prefix={<LockOutlined style={{color: 'rgba(255,255,255,.25)'}}/>}
                            />
                        </Form.Item>

                        <Form.Item<LoginType>>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            {/*<Link to={`/register`} style={{float: "right"}}>Forgot password</Link>*/}
                        </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit" className="login-form-button">Log in</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
    )
}