import {PortfolioTable} from "../components/PortfolioTable";
import {Total} from "../components/Total";
import {usePortfolioData} from "./LayoutPage";

export const Portfolio = () => {
    const {data: portfolioPageData} = usePortfolioData()
    //TODO Не отображать автоматом ошибки получанеия данных после выполнения запроса. Сделать для этого отдельный компонент
    return (
        <>
            <PortfolioTable
                accounts={portfolioPageData.accounts}
                summary={portfolioPageData.summary}
            />
            <Total
                totals={portfolioPageData.totals}
            />
        </>
    )
}