import {theme} from "antd";
import {IValue} from "./ColoredValuePercents";

const {useToken} = theme;

export const MarginValue = ({value, style}: IValue) => {
    const {token} = useToken();
    return (
        <>
            <div style={{...style, color: token.orange}}>{value}%</div>
        </>
    )
}