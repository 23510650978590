import {Table} from "antd";
import {PortfolioSummary, SummaryType} from "./PortfolioSummary";
import {ColumnsType} from "antd/es/table";
import {AccountInfoCell, IAccountInfo} from "./AccountInfoCell";
import {IMoneyInfo, MoneyInfoCell} from "./MoneyInfoCell";
import React from "react";
import {useNavigate} from "react-router-dom";

export interface AccountType {
    key: React.Key;
    info: IAccountInfo;
    today: IMoneyInfo;
    drawdown: IMoneyInfo;
    total: IMoneyInfo;
}

interface TableDataType {
    accounts: AccountType[],
    summary: SummaryType
}

const columns: ColumnsType<AccountType> = [
    {
        title: 'Советник',
        dataIndex: 'info',
        key: 'info',
        render: (info) => <AccountInfoCell {...info}/>,
    },
    {
        title: 'Сегодня',
        dataIndex: 'today',
        key: 'today',
        render: (info) => <MoneyInfoCell {...info}/>
    },
    {
        title: 'Просадка',
        dataIndex: 'drawdown',
        key: 'drawdown',
        render: (info) => <MoneyInfoCell {...info}/>
    },
    {
        title: 'Всего',
        dataIndex: 'total',
        key: 'total',
        render: (info) => <MoneyInfoCell {...info}/>
    }
];

export const PortfolioTable = (tableData: TableDataType) => {
    //TODO Не отображать автоматом ошибки получанеия данных после выполнения запроса. Сделать для этого отдельный компонент
    const navigate = useNavigate();
    return (
        <>
            <Table
                columns={columns}
                dataSource={tableData.accounts}
                pagination={false}
                size="middle"
                onRow={(record) => ({onClick: () => navigate(`/accounts/${record.info.id}`)})}
                summary={() => <PortfolioSummary {...(tableData.summary)}/>}
            />;
        </>
    )
}