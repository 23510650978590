import {theme} from "antd";
import {CSSProperties, useEffect, useState} from "react";

const {useToken} = theme;

interface RefreshTimerType {
    lastUpdate: number,
    style?: CSSProperties | undefined;
}

function calculateTimeOffset(baseTime: number) {
    return Math.trunc((Date.now() - baseTime) / 1000)
}

export const RefreshTimer = (tableHeader: RefreshTimerType) => {
    const [counter, setCounter] = useState(calculateTimeOffset(tableHeader.lastUpdate));
    const {token} = useToken();
    useEffect(() => {
        setCounter(calculateTimeOffset(tableHeader.lastUpdate));
        const timer = setInterval(() => {
            setCounter(calculateTimeOffset(tableHeader.lastUpdate));
        }, 1000);
        return () => clearInterval(timer);
    }, [tableHeader.lastUpdate]);
    const warning = counter > 30
    const color = warning ? token.colorError : token.colorSuccess
    return (
        <>
            <div style={{...tableHeader.style, textAlign: "center", color: color}}>Последнее обновление {counter} секунд назад</div>
        </>
    )
}