import {useSwipeable} from "react-swipeable";
import {Drawer} from "antd";
import {DrawerProps} from "antd/es/drawer";
import React from "react";
import {DrawerStyles} from "antd/es/drawer/DrawerPanel";

export interface SwipableDrawerProps extends DrawerProps {
    onSwipeClose?: () => void;
}

export const SwipeableDrawer = (props: SwipableDrawerProps) => {
    const {onSwipeClose} = props;
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            onSwipeClose && onSwipeClose();
            // console.log("swiped")
        },
        // onSwiped: () => setStopScroll(false)
    });
    const drawerStyles: DrawerStyles = {
        ...props.styles,
        body: {
            ...props.styles?.body,
            padding: 0,
            display: "flex"
        }
    };


    return (
        <Drawer{...props} styles={drawerStyles}>
            <div {...handlers} style={{
                padding: props.styles?.body?.padding || "24px",
                flex: 1,
                boxSizing: "border-box"
            }}>{props.children}</div>
        </Drawer>
    )
}