import {theme} from "antd";

const {useToken} = theme;

export interface IMoneyInfo {
    percent: number,
    real: number,
    currency: string,
}

export const MoneyInfoCell = (props: IMoneyInfo) => {
    const {token} = useToken();
    const {percent, real, currency} = props
    const percentColor = percent > 0 ? token.colorSuccess : percent < 0 ? token.colorError : token.colorTextBase
    return (
        <>
            <div style={{color: percentColor, fontSize: "18px"}}>{percent > 0 ? "+" : ""}{percent}%</div>
            <div style={{fontSize: "12px"}}>{real > 0 ? "+" : ""}{real} {currency}</div>
        </>
    )
}