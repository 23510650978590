import {theme} from "antd";
import {CSSProperties} from "react";

const {useToken} = theme;

export interface IValue {
    value: number,
    currency: String,
    neutral?: boolean | false,
    style?: CSSProperties | undefined;
}

export const ColoredMoney = ({value, style, currency, neutral}: IValue) => {
    const {token} = useToken();
    const color = neutral
        ? value === 0 ? token.colorTextBase : token.cyan7
        : value > 0 ? token.colorSuccess : value < 0 ? token.colorError : token.colorTextBase
    return (
        <>
            <div style={{...style, color: color}}>{value > 0 ? "+" : ""}{value}{currency}</div>
        </>
    )
}