export function groupBy<T,K>(array: T[], keySelector: (value: T) => K): Map<K, T[]> {
    const groupedMap: Map<K, T[]> = new Map();
    for (const current of array) {
        let key = keySelector(current)
        let thisList = groupedMap.get(key);
        if (thisList === undefined) {
            thisList = [];
            groupedMap.set(key, thisList);
        }
        thisList.push(current);
    }
    return groupedMap;
}