import {privateApi} from "../config/AxiosConfig";
import {QueryClient, useSuspenseQuery} from "@tanstack/react-query";
import {SummaryType} from "../components/PortfolioSummary";
import {Totals} from "../components/Total";
import {AccountType} from "../components/PortfolioTable";
import {Button, Flex, Layout, theme} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import {RefreshTimer} from "../components/RefreshTimer";
import {Outlet} from "react-router-dom";
import {MenuOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {SwipeableDrawer} from "../components/SwipableDrawer";

const {useToken} = theme;

interface PortfolioResponseType {
    accounts: PortfolioAccountResponseType[]
    summary: SummaryType,
    totals: Totals
}

interface PortfolioAccountResponseType {
    id: string,
    name: string,
    days: number,
    type: string,
    sellOrdersCount: number,
    buyOrdersCount: number,
    currency: string,
    today: number,
    todayPercent: number,
    drawdown: number,
    drawdownPercent: number,
    total: number,
    totalPercent: number,
    marginLevel: number
}

interface PortfolioPageDataType {
    accounts: AccountType[],
    summary: SummaryType,
    totals: Totals
}

const toPortfolioPageData = (response: PortfolioResponseType): PortfolioPageDataType => ({
    accounts: response.accounts.map(account => ({
        key: account.id,
        info: {
            name: account.name,
            id: account.id,
            days: account.days,
            type: account.type,
            sellOrdersCount: account.sellOrdersCount,
            buyOrdersCount: account.buyOrdersCount
        },
        today: {
            percent: account.todayPercent,
            real: account.today,
            currency: account.currency
        },
        drawdown: {
            percent: account.drawdownPercent,
            real: account.drawdown,
            currency: account.currency
        },
        margin: account.marginLevel,
        total: {
            percent: account.totalPercent,
            real: account.total,
            currency: account.currency
        }
    })),
    summary: response.summary,
    totals: response.totals
})

const portfolioQuery = () => ({
    queryKey: ['portfolio'],
    queryFn: async () => privateApi('/portfolio', {withCredentials: true})
        .then(response => toPortfolioPageData(response.data)),
    // select: (response: AxiosResponse) => toPortfolioPageData(response.data),
    staleTime: 1000 * 15,//TODO  вовремя не обновляется. Ждет следующего интервала.
    // retry: true, //TODO попробовать включить если надо
    //retryDelay: 1000,
    refetchInterval: 1000 * 15, //TODO сделать разное обновление
})

export const portfolioLoader = (queryClient: QueryClient) => async (): Promise<PortfolioPageDataType> => {
    return queryClient.fetchQuery(portfolioQuery())
}

export const LayoutPage = () => {
    const [open, setOpen] = useState(false);
    const {dataUpdatedAt, isFetching, refetch} = usePortfolioData()
    const {token} = useToken();
    const openMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        setOpen(true);
    }
    return (
        <>
            <Layout>
                <Header
                    style={{position: 'sticky', top: 0, zIndex: 1, borderBottom: `1px solid ${token.colorBorder}`}}
                    onClick={() => refetch()}
                >
                    <Flex justify={"space-between"} align={"center"}>
                        <Button type="link" style={{color: token.colorText}} icon={<MenuOutlined/>} onClick={openMenu}></Button>
                        <RefreshTimer lastUpdate={dataUpdatedAt}></RefreshTimer>
                        {/*<Spin style={{padding: "5px", visibility: isFetching ? "visible" : "hidden"}} indicator={<LoadingOutlined spin />}/>*/}
                        <Button
                            type="link"
                            style={{color: token.colorText}}
                            icon={<ReloadOutlined/>}
                            loading={isFetching}
                            onClick={() => refetch()}
                        />
                    </Flex>

                </Header>
                <Content style={{color: "black"}}>
                    <Outlet/>
                    <SwipeableDrawer
                        onSwipeClose={() => setOpen(false)}
                        // title="Basic Drawer"
                        // size={"default"}
                        style={{width:"300px"}}
                        placement="left"
                        closable={false}
                        onClose={() => setOpen(false)}
                        open={open}
                        // key={placement}
                    >
                    </SwipeableDrawer>
                </Content>
            </Layout>
        </>
    )
}

export const usePortfolioData = () => useSuspenseQuery(portfolioQuery())