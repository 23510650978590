import { format } from "date-fns";

export function timestampToDateTimeString(value?: number): string {
    if(value == null) return "Нет данных"
    const date = new Date(value);
    return `${format(date, "dd.MM.yyyy")} | ${timestampToTimeString(value)}`
}

export function timestampToTimeString(value?: number): string {
    if(value == null) return "Нет данных"
    const date = new Date(value);
    return format(date, "HH:mm:ss");
}